<template>
	<div class="login">
		<Captcha :showCaptcha="isShow" @captcha="captcha" />
		<div class="bg" :class="psFlag ? 'psBg' :''"></div>
		<div class="login-container" :class="psFlag ? 'ps-login-container' :''">
			<div class="login-logo" v-if="!psFlag">
				<img  src="../../images/logo_regi.png">
			</div>
			<el-form v-show="isLogPwd" class="login-form" :class="psFlag ? 'ps-login-form' :''" autoComplete="on" :model="loginForm" :rules="loginRules" ref="loginForm"
				label-position="left">
				<div class="ps-title" v-if="psFlag">
					<!-- 判断psFlag等于true是普盛很能猜的时候普盛logo显示，否则不显示 -->
					<img src="../../images/ps-logo.png">
					<div>普盛很能猜系统</div>
				</div>
				<el-form-item prop="login">
					<i class="ps-phone-icon" v-if="psFlag"></i>
					<el-input name="login" type="text" :class="psFlag ? 'ps-phone' :''" maxLength="11" v-model="loginForm.login" autoComplete="on" placeholder="请输入手机号" />
				</el-form-item>
				<el-form-item prop="pwd">
					<i class="ps-pwd-icon" v-if="psFlag"></i>
					<el-input name="pwd" :type="pwdType" :class="psFlag ? 'ps-pwd' :''" @keyup.enter.native="login" v-model="loginForm.pwd"
						autoComplete="on" placeholder="请输入密码"></el-input>
				</el-form-item>
				<div class="clearfix">
					<div class="log-type green-txt" @click="logType" >验证码登录</div>
				</div>
				<el-form-item class="clearfix">
					<el-button type="primary" style="width:100%;" :loading="loading" @click.native.prevent="login">
						登录
					</el-button>
				</el-form-item>
				<!-- <div class="tips">登录</div> -->
			</el-form>
			<el-form v-show="!isLogPwd" class="login-form" :class=" psFlag ? 'ps-login-form' :''" autoComplete="on" :model="loginForm1" :rules="loginRules1"
				ref="loginForm1" label-position="left">
				<!-- <div class="ps-title" v-if="psFlag">普盛很能猜系统</div> -->
				<div class="ps-title" v-if="psFlag">
					<!-- 判断psFlag等于true是普盛很能猜的时候普盛logo显示，否则不显示 -->
					<img src="../../images/ps-logo.png">
					<div>普盛很能猜系统</div>
				</div>
				<el-form-item prop="mobile">
					<i class="ps-phone-icon" v-if="psFlag"></i>
					<el-input name="mobile" type="text" :class="psFlag ? 'ps-phone' :''" maxLength="11" v-model="loginForm1.mobile" autoComplete="on" placeholder="请输入手机号" />
				</el-form-item>
				<el-form-item prop="code">
					<div class="clearfix">
						<el-input style="width: 180px;float: left;" :style="[{width:(psFlag ? '230px' : '180px')}]" maxLength="4" name="code" type="text" v-model="loginForm1.code" autoComplete="on"
							placeholder="请输入验证码" />
						<div v-show="codeTime" @click="getCaptcha" class="r codeBtn">发送验证码</div>
						<div v-show="!codeTime" class="r codeBtn gray">{{countTime}}s</div>
					</div>
				</el-form-item>
				<div class="clearfix">
					<div class="log-type green-txt" @click="logType" >密码登录</div>
				</div>
				<el-form-item>
					<el-button type="primary" style="width:100%;" :loading="loading" @click.native.prevent="smsLogin">
						登录
					</el-button>
				</el-form-item>
			</el-form>
			<div class="log-foot clearfix" :class="psFlag ? 'ps-log-foot' :''">
				<div class="bl" :class="psFlag ? '' :'r'">
					<span @click="toPath('forgetPwd')" class="span green-txt">忘记密码</span>
					<span @click="toPath('register')" class="span green-txt" :class="psFlag ? '' :'green-txt'">注册</span>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import Captcha from '@/components/Captcha.vue'
	import { login , getlogincode , smslogin} from '@/api/permission'
	export default {
		components: {
			Captcha,
		},
		data() {
			const validateUsername = (rule, value, callback) => {
				if (value.length < 11) {
					callback(new Error('请输入正确的手机号'))
				} else {
					callback()
				}
			}
			const validatePass = (rule, value, callback) => {
				if (value.length < 3) {
					callback(new Error('密码不能小于3位'))
				} else {
					callback()
				}
			}
			const validateCode = (rule, value, callback) => {
				if (value.length < 4) {
					callback(new Error('请输入正确的验证码'))
				} else {
					callback()
				}
			}
			return {
				returnURL: '',
				countTime: 120,
				codeTime: true,//倒计时
				timer: null,
				isShow: false,
				isLogPwd: true,//密码登录
				loginForm: {
					login: '',
					pwd: '',
				},
				loginForm1: {
					mobile: '',
					code: '',
				},
				loginRules: {
					login: [{
							required: true,
							trigger: 'blur',
							validator: validateUsername
					}],
					pwd: [
						{ required: true, trigger: 'blur', validator: validatePass }
					]
				},
				loginRules1: {
					mobile: [{
						required: true,
						trigger: 'blur',
						validator: validateUsername
					}],
					code: [{ required: true, trigger: 'blur', validator: validateCode }
				]},
				loading: false,
				pwdType: 'password',
				psFlag:false,//区分是普盛很能猜还是saas平台很能猜登录页面，false是saas平台，true是普盛很能猜系统
			}
		},
		mounted() {
			this.returnURL = this.$route.query.returnURL
			this.loginForm.login = this.$route.query.login
			// 000新增判断是saas平台还是普盛很能猜
			let url = window.location.href
			if(url.indexOf("login.pushengfood.com") == -1){
				this.psFlag = false
			}else{
				this.psFlag = true
			}
		},
		methods: {
			captcha(captcha){
				this.isShow = false
				this.getCode(captcha)
			},
			logType(){
				this.loginForm1.mobile = this.loginForm1.mobile ? this.loginForm1.mobile : this.loginForm.login
				this.loginForm.login = this.loginForm.login ? this.loginForm.login : this.loginForm1.mobile
				this.isLogPwd = !this.isLogPwd
			},
			async getCaptcha(){
				let mobile = this.loginForm1.mobile || ''
				if(mobile.length !== 11){
					this.$message({
						message: '请输入正确的手机号！',
						type: 'warning'
					});
					return
				}else {
					this.isShow = true
				}
			},
			async getCode(captcha){
				let params = {
					mobile: this.loginForm1.mobile,
					captcha: captcha
				}
				try {
					await getlogincode(params)
					this.codeTimer()
					this.$message({
						message: '发送成功！',
						type: 'success'
					});
				}catch(e){
					console.log(e)
				}
			},
			codeTimer(){
				const TIME_COUNT = 120;
				if (!this.timer) {
					this.countTime = TIME_COUNT;
					this.codeTime = false;
					this.timer = setInterval(() => {
						if (this.countTime > 0 && this.countTime <= TIME_COUNT) {
							this.countTime--;
						} else {
							this.codeTime = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
			},
			async smsLogin(){//短信登录
				let params = {
					login: this.loginForm1.mobile,
					code: this.loginForm1.code,
					returnURL: this.returnURL
				}
				try {
					let data = await smslogin(params)
					let token = data.token
					let phone = data.login
					if(data.returnURL){
						if(data.returnURL == "https://login.hennengcai.com/subsys/index"){
							localStorage.setItem('username',phone)
							this.$store.commit('LOGIN_IN', token)
							this.$router.replace('/subsys')
						}else {
							window.location.href = data.returnURL
						}
					}else {
						this.$message({
							showClose: true,
							message: '出错了，缺失returnURL参数',
							duration: 0,
							type: 'error'
						});
					}
				}catch(e){
					console.log(e)
				}
			},
			showPwd() {
				if (this.pwdType === 'password') {
					this.pwdType = ''
				} else {
					this.pwdType = 'password'
				}
			},
			toPath(url){
				this.$router.push({
					path:`/${url}`,
					query: {
						mobile: this.loginForm.login
					}
				})
			},
			async login() {
				try {
					this.loginForm.returnURL = this.returnURL
					let data = await login(this.loginForm)
					let token = data.token
					let phone = data.login
					if(data.returnURL){
						if(data.returnURL == "https://login.hennengcai.com/subsys/index" || data.returnURL ==
						"http://logintest.hennengcai.com/subsys/index"){
							localStorage.setItem('username',phone)
							this.$store.commit('LOGIN_IN', token)
							this.$router.replace('/subsys')
						}else {
							window.location.href = data.returnURL
						}
					}else {
						this.$message({
							showClose: true,
							message: '出错了，缺失returnURL参数',
							duration: 0,
							type: 'error'
						});
					}
				} catch (e) {
					console.log(e)
				}
			}
		}
	}
</script>
<style>

</style>
<style scoped lang="less">

@import "./login.less";

</style>
