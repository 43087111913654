import Vue from 'vue'
import VueRouter from 'vue-router'

// web
import Agreement from '@/pages/login/Agreement'
import PrivacyPolicy from '@/pages/login/PrivacyPolicy'
import Login from '@/pages/login/Login'
import Register from '@/pages/login/Register'
import ForgetPwd from '@/pages/login/Pwd'


// import Home from '../views/Home.vue'
//外蛋系统管理
import Subsys from '@/pages/subsys/Index.vue'
import SubsysList from '@/pages/subsys/system-list/Index.vue'
import SubsysUser from '@/pages/subsys/system-user/Index.vue'
import SubsysAdd from '@/pages/subsys/add/Index.vue'
import SubsysDetails from '@/pages/subsys/details/Index.vue'
//超级管理员
import Admin from '@/pages/admin/Index.vue'
import AdminList from '@/pages/admin/index/Index.vue'
import AdminAdd from '@/pages/admin/add/Index.vue'
//用户管理
import Manage from '@/pages/manage/Index.vue'
import ManageList from '@/pages/manage/index/Index.vue'
import ManageAdd from '@/pages/manage/add/Index.vue'
//编号管理
import Identifier from '@/pages/identifier/Index.vue'
import IdentifierList from '@/pages/identifier/index/Index.vue'
//日志管理
import Weblog from '@/pages/weblog/Index.vue'
import WeblogList from '@/pages/weblog/index/Index.vue'
//公司管理
import Company from '@/pages/company/Index.vue'
import CompanyList from '@/pages/company/index/Index.vue'
//微信登录
import Wxlogin from '@/pages/wxlogin/Index.vue'
import WxloginList from '@/pages/wxlogin/index/Index.vue'
import WxloginAdd from '@/pages/wxlogin/add/Index.vue'
import WxloginDetails from '@/pages/wxlogin/details/Index.vue';

Vue.use(VueRouter)

/* 初始路由 */
export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/subsys/index'
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/register',
      component: Register
    },
    {
      path: '/forgetPwd',
      component: ForgetPwd
    },
    {
      path: '/agreement',
      component: Agreement
    },
    {
      path: '/privacyPolicy',
      component: PrivacyPolicy
    },
    {
      path: '/subsys',
      name: 'subsys',
      component: Subsys,
      redirect: '/subsys/index',
      children: [
        {
          path: '/subsys/index',
          component: SubsysList,
          name: 'subsysList'
          // meta: {
          //   name: '我是一个搬砖工',
          //   icon: 'tree'
          // }
        },
        {
          path: '/subsys/user',
          component: SubsysUser,
          name: 'subsysUser'
        },
        {
          path: '/subsys/add',
          component: SubsysAdd,
          name: 'subsysAdd'
        },
        {
          path: '/subsys/details',
          component: SubsysDetails,
          name: 'subsysDetails'
        }
      ]
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      redirect: '/admin/list',
      children: [
        {
          path: '/admin/list',
          component: AdminList,
          name: 'adminList'
        },
        {
          path: '/admin/add',
          component: AdminAdd,
          name: 'adminAdd'
        }
      ]
    },
    {
      path: '/manage',
      name: 'manage',
      component: Manage,
      redirect: '/manage/index',
      children: [
        {
          path: '/manage/index',
          component: ManageList,
          name: 'manageList'
        },
        {
          path: '/manage/add',
          component: ManageAdd,
          name: 'manageAdd'
        },
      ]
    },
    {
      path: '/identifier',
      name: 'identifier',
      component: Identifier,
      redirect: '/identifier/index',
      children: [
        {
          path: '/identifier/index',
          component: IdentifierList,
          name: 'identifierList'
        },
      ]
    },
    {
      path: '/weblog',
      name: 'weblog',
      component: Weblog,
      redirect: '/weblog/index',
      children: [
        {
          path: '/weblog/index',
          component: WeblogList,
          name: 'weblogList'
        }
      ]
    },
    {
      path: '/company',
      name: 'company',
      component: Company,
      redirect: '/company/index',
      children: [
        {
          path: '/company/index',
          component: CompanyList,
          name: 'companyList'
        }
      ]
    },
    {
      path: '/wxlogin',
      name: 'wxlogin',
      component: Wxlogin,
      redirect: '/wxlogin/index',
      children: [
        {
          path: '/wxlogin/index',
          component: WxloginList,
          name: 'wxloginList'
        },
        {
          path: '/wxlogin/add',
          component: WxloginAdd,
          name: 'wxloginAdd'
        },
        {
          path: '/wxlogin/details',
          component: WxloginDetails,
          name: 'wxloginDetails'
        }
      ]
    }
  ]
});
/* 准备动态添加的路由 */
export const DynamicRoutes = [
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    children: [{
      id: 1,
      path: '/admin',
      component: Admin,
      name: 'admin',
      meta: {
        name: '首页',
        icon: 'tree'
      }
    }]
  }
]
// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router
