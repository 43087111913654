<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">编辑手机号1</div>
		</div>
		<div class="content">
			<div class="bl lr-form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="手机号码" prop="mobile2">
						<el-input v-model="ruleForm.mobile2"></el-input>
					</el-form-item>
					<el-form-item class="submit">
						<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
						<el-button @click="toPath('/manage')">返回</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import { switchboardIdentityUpdate } from '@/api/permission'
	export default {
		data() {
			return {
				type: 'add',
				ruleForm: {
					id:'',
					mobile: '',
					mobile2:'',
				},
				rules: {
					mobile2: [
						{ required: true, message: '请输入手机号码', trigger: 'blur' },
					]
				}
			}
		},
		created() {
			if(this.$route.query.id || this.$route.query.mobile){//编辑
				this.ruleForm.id = this.$route.query.id
				this.ruleForm.mobile = this.$route.query.mobile
			}
		},
		mounted () {
		},
		methods: {
			toPath(path){
				this.$router.push(path)
			},
			submitForm(formName) {
				let _this = this
				console.log(this.ruleForm)
				this.$refs[formName].validate(async(valid) => {
					if (valid) {
						console.log(valid,this.ruleForm)
						try{
							let info = await switchboardIdentityUpdate(this.ruleForm)
							console.log(info)
							_this.$message({
								message: '修改成功！',
								type: 'success'
							});
							_this.toPath('/manage')
						}catch(e){
							console.log(e)
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
		},

	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>