<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">超级管理员</div>
			<div @click="toPath" class="sub-nav-common sub-nav-btn">添加</div>
		</div>
		<!-- <div class="search">
			<div class="item clearfix">
				<div class="label clearfix l">
					<span class="label-txt l">手机号码：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" v-model="input2">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">状态：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" v-model="input2">
						</el-input>
					</div>
				</div>
			</div>
		</div> -->
		<div class="content">
			<el-table :data="tableData" stripe style="width: 100%">
				<el-table-column prop="mobile" label="手机号码" width="180">
				</el-table-column>
				<el-table-column prop="status" label="状态" width="180">
				</el-table-column>
				<el-table-column prop="createdAt" label="创建时间" width="180">
				</el-table-column>
				<el-table-column label="操作">
					<template scope="scope">
						<div class="handle">
							<span class="link" @click="handleDelete(scope.row)">删除</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	import { adminShow, adminDelete } from '@/api/permission'
	export default {
		data() {
			return {
				input2: '',
				tableData: [{}]
			}
		},
		created() {
			this.getList()
		},
		methods: {
			toPath(){
				this.$router.push({
					path: '/admin/add'
				});//传参
			},
			async getList(){
				try{
					let data = await adminShow()
					this.tableData = data.results
					console.log(data)
				}catch(e){
					console.log(e)
				}
			},
			async handleDelete(row){
				try{
					await adminDelete(row.id)
					this.$message({
						message: '删除成功！',
						type: 'success'
					});
					this.getList()
				}catch(e){
					console.log(e)
				}
			}
		},
	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>