<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">详情</div>
		</div>
		<div class="content">
			<div class="bl lr-form" v-if="formData">
				<el-form :model="formData" ref="formData" label-width="100px" class="demo-ruleForm">
					<el-form-item label="secret" prop="secret">
						<el-input v-model="formData.secret"></el-input>
					</el-form-item>
					<el-form-item label="appId" prop="appId">
						<el-input v-model="formData.appId"></el-input>
					</el-form-item>
					<el-form-item label="name" prop="name">
						<el-input v-model="formData.name"></el-input>
					</el-form-item>
					<el-form-item class="submit">
						<el-button @click="toPath">返回</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import { wxAppShow } from '@/api/permission'
	export default {
		data() {
			return {
				formData: null,
			}
		},
		created() {
			if(this.$route.query.id){//编辑
				this.id = this.$route.query.id
				console.log(this.id)
				this.getItem(this.$route.query.id)
			}
		},
		methods: {
			onCopy() {
				this.$message({
					message: '复制成功！',
					type: 'success'
				});
			},
			onError(e) {
				alert('复制失败',e)
			},
			toPath(){
				this.$router.push('/wxlogin')
			},
			async getItem(id){
				try{
					let data = await wxAppShow(id)
					console.log(data)
					this.formData = data
				}catch(e){
					console.log(e)
				}
			},
		},

	}
</script>
<style scoped lang="less">
	@import "../../../styles/_utils.less";
	@import "../../../styles/_variables.less";
  .content input.el-input__inner {
		background: red;
	}
	.copy {
		display: inline-block;
		position: absolute;
		top: 5px;
		left: 240px;
		background: @green;
		padding: 0 10px;
		border-radius: 3px;
		height: 30px;
		line-height: 30px;
		color: @white;
		&:hover {
			cursor: pointer;
			opacity: .9;
		}
	}
</style>