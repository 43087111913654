<template>
	<div>
		<div class="sub-nav clearfix">
			<div class="l">
				<div class="sub-nav-common sub-nav-title">用户列表</div>
			</div>
			<div class="r">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
					:page-sizes="[20, 30, 50]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="pages.totalRecords">
				</el-pagination>
			</div>
		</div>
		<div class="search">
			<div class="item clearfix">
				<!-- <div class="label clearfix l">
					<span class="label-txt l">手机号码：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" v-model="input2">
						</el-input>
					</div>
				</div> -->
				<div class="label clearfix l">
					<span class="label-txt l">登录号：</span>
					<div class="label-input l">
						<el-input @keyup.enter.native="handleSearch" placeholder="请输入内容" v-model="search.login">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">用户名称：</span>
					<div class="label-input l">
						<el-input @keyup.enter.native="handleSearch" placeholder=" 请输入内容" v-model="search.name">
						</el-input>
					</div>
				</div>
				<!-- <span @click="handleSearch" class="search-btn"></span> -->
				<!-- <div class="label clearfix l">
					<span class="label-txt l">状态：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" v-model="input2">
						</el-input>
					</div>
				</div> -->
			</div>
		</div>
		<div class="content">
			<el-table
			:data="tableData"
			style="width: 100%"
			:max-height="tableHeight ? tableHeight : 0">
				<el-table-column class="headPic" prop="headPic" label="头像" width="180">
					<template scope="scope">
						<img :src="scope.row.headPic">
					</template>
				</el-table-column>
				<el-table-column prop="login" label="登录号" width="180">
				</el-table-column>
				<el-table-column prop="mobile" label="手机号码" width="180">
				</el-table-column>
				<el-table-column prop="name" label="用户名称">
				</el-table-column>
				<el-table-column prop="email" label="邮箱">
				</el-table-column>
				<el-table-column prop="privacy" label="privacy">
				</el-table-column>
				<el-table-column prop="status" label="状态" width="180">
					<template scope="scope">
						{{scope.row.status}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="操作" width="180">
					<template scope="scope">
						<div class="handleBtn" @click="changeMobile(scope.row)">更换手机号</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	import { adminUser } from '@/api/permission'
	export default {
		data() {
			return {
				tableHeight: '',
				search: {
					login: '',
					name: '',
				},
				pages: {
					page: 1,
					pageSize: 20,
				},
				tableData: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			changeMobile(row){
				console.log(row)
				this.$router.push({
					path: '/manage/add',
					query:{
						id: row.id,
						mobile: row.mobile,
					}
				});//传参
			},
			handleSizeChange(val) {
				this.pages.pageSize = val
				console.log(`每页 ${val} 条`);
				let query = `pageSize=${val}&page=${this.pages.page}`
				this.getList(query)
      },
      handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				let query = `pageSize=${this.pages.pageSize}&page=${val}&login=${this.search.login}&name=${this.search.name}`
				this.getList(query)
			},
			handleSearch(){
				let query =
				`pageSize=${this.pages.pageSize}&page=${this.pages.page}&login=${this.search.login}&name=${this.search.name}`
				this.getList(query)
			},
			async getList(query){
				query = query ? query : ''
				try{
					let data = await adminUser(query)
					let pages = {}
					pages.page = data.page
					pages.pageSize = data.pageSize
					pages.totalPages = data.totalPages
					pages.totalRecords = data.totalRecords
					this.pages = pages
					this.tableData = data.results
					this.$nextTick(function () {
						this.tableHeight = this.utils.getTableHeight()
					})
					console.log(data)
				}catch(e){
					console.log(e)
				}
			}
		},
	}
</script>
<style scoped lang="less">
	.cell {
		line-height: 1;
		img {
			display: inline-block;
			width: 40px;
			height: 40px;
		}
	}
	.handleBtn {
		width: 120px;
		height: 36px;
		text-align: center;
		font-size: 16px;
		line-height: 36px;
		color: #fff;
		background: #55C4B8;
		border-radius: 5px;
		&:hover {
			cursor: pointer;
		}
	}
</style>