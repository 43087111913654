<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">添加管理员</div>
		</div>
		<div class="content">
			<div class="bl lr-form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="手机号码" prop="mobile">
						<el-input v-model="ruleForm.mobile"></el-input>
					</el-form-item>
					<el-form-item class="submit">
						<el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
						<el-button @click="toPath('/admin')">返回</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import { adminSave } from '@/api/permission'
	export default {
		data() {
			return {
				type: 'add',
				ruleForm: {
					mobile:'',
				},
				rules: {
					mobile: [
						{ required: true, message: '请输入手机号码', trigger: 'blur' },
					]
				}
			}
		},
		methods: {
			toPath(path){
				this.$router.push(path)
			},
			submitForm(formName) {
				let _this = this
				this.$refs[formName].validate(async(valid) => {
					if (valid) {
						try{
							await adminSave(this.ruleForm)
							_this.$message({
								message: '添加成功！',
								type: 'success'
							});
							_this.toPath('/admin')
						}catch(e){
							console.log(e)
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
		},

	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>