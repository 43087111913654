import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import '@/icons'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/main.less'

import axios from './config/httpConfig'
import VueClipboard from 'vue-clipboard2';
import Utils from './utils/utils.js';

Vue.prototype.utils = Utils;
Vue.prototype.$http = axios
Vue.prototype.$ = $; // 当然还有这句话 给vue原型上添加 $
Vue.use(VueClipboard);
Vue.use(ElementUI)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  console.log(to)
  console.log(from)
  console.log(next)
  if (!store.state.UserToken) {//无token
    if (to.matched.length > 0 && !to.matched.some(record => record.meta.requiresAuth)) {
      next()
    } else {
      next({
        path: '/login'
      })
    }
  } else {
    next()
    // if (to.path !== '/login') {
    //   next()
    // } else {
    //   next(from.fullPath)
    // }
  }
  // if (!store.state.UserToken) {
  //   if (to.matched.length > 0 && !to.matched.some(record => record.meta.requiresAuth)) {
  //     next()
  //   } else {
  //     next({
  //       path: '/login'
  //     })
  //   }
  // } else {
  //   if (!store.state.permission.permissionList) {
  //     store.dispatch('permission/FETCH_PERMISSION').then(() => {
  //       next({
  //         path: to.path
  //       })
  //     })
  //   } else {
  //     if (to.path !== '/login') {
  //       next()
  //     } else {
  //       next(from.fullPath)
  //     }
  //   }
  // }
})

// router.afterEach((to, from, next) => {
//   console.log(from,next)
//   var routerList = to.matched
//   store.commit('setCrumbList', routerList)
//   store.commit('permission/SET_CURRENT_MENU', to.name)
// })



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
