<template>
	<div>
		<div class="sub-nav clearfix">
			<div class="l">
				<div class="sub-nav-common sub-nav-title">编号列表</div>
			</div>
			<div class="r">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
					:page-sizes="[20, 30, 50]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next, jumper"
					:total="pages.totalRecords">
				</el-pagination>
			</div>
		</div>
		<div class="search">
			<div class="item clearfix">
				<div class="label clearfix l">
					<span class="label-txt l">id：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.id">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">编号：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.name">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">姓名：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.alias">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">电话：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.mobile">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">部门：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.departmentId">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">角色id：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.roleId">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">状态：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.status">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">用户id：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.userId">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">公司id：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.switchboardId">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">身份类型：</span>
					<div class="label-input l">
						<el-select
						clearable
						@change="handleSearch"
						v-model="search.type"
						placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</div>
				</div>
				<!-- <span @click="handleSearch" class="search-btn"></span> -->
			</div>
		</div>
		<div class="content">
			<el-table
			:data="tableData"
			:max-height="tableHeight ? tableHeight : 0"
			style="width: 100%">
				<el-table-column label="序号" min-width="50">
					<template scope="scope">
						{{pages.pageSize*(pages.page-1)+scope.$index+1}}
					</template>
				</el-table-column>
				<el-table-column prop="name" label="编号" width="180">
				</el-table-column>
				<el-table-column prop="alias" label="姓名" width="180">
				</el-table-column>
				<el-table-column prop="mobile" label="使用者电话" width="180">
				</el-table-column>
				<el-table-column prop="type" label="身份类型" width="100">
					<template scope="scope">
						<template v-if="scope.row.type === '1'">个人身份</template>
						<template v-else-if="scope.row.type === '2'">公司身份</template>
						<template v-else>其他身份</template>
					</template>
				</el-table-column>
				<el-table-column prop="status" label="编号状态" width="180">
					<template scope="scope">
						{{scope.row.status}}
					</template>
				</el-table-column>
				<el-table-column prop="id" label="id" width="300">
				</el-table-column>
				<el-table-column prop="switchboardId" label="公司id" width="300">
				</el-table-column>
				<el-table-column prop="departmentId" label="部门id" width="300">
				</el-table-column>
				<el-table-column prop="roleId" label="角色id" width="300">
				</el-table-column>
				<el-table-column prop="remark" label="备注" width="180">
				</el-table-column>
				<el-table-column prop="" label="统计" width="180">
				</el-table-column>
				<el-table-column prop="createdAt" label="创建日(倒序)" width="180">
				</el-table-column>
				<el-table-column prop="acceptedAt" label="使用者同意日" width="180">
				</el-table-column>
				<el-table-column prop="stopAt" label="终止日" width="180">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="70">
					<template scope="scope">
						<div class="handle">
							<span class="link" @click="handleDelete(scope.row)">删除</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	import { switchboardIdentity, switchboardIdentityDelete } from '@/api/permission'
	export default {
		data() {
			return {
				tableHeight: '',
				options: [
					{
						label: '个人身份',
						value: '1',
					},
					{
					label: '公司身份',
					value: '2',
					},
					{
					label: '其他身份',
					value: '3',
					}
				],
				search: {
					id: '',//id
					name: '',//编号
					alias: '',//昵称姓名
					switchboardId: '',//公司id
					type: '',//类型
					userId: '',//用户id
					departmentId: '',//部门id
					roleId: '',//角色id
					status: '',//状态
					mobiel: '',//手机号码
				},
				pages: {
					page: 1,
					pageSize: 20,
				},
				current: 2,
				input2: '',
				tableData: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getQuery(){
				let query = ''
				let search = this.search
				for(var i in search) {
					if(search[i]){
						query += `&${i}=${search[i]}`
					}
				}
				return `${query}&`
			},
			handleSizeChange(val) {
				this.pages.pageSize = val
				let query = this.getQuery()
				query += `pageSize=${val}&page=${this.pages.page}`
				this.getList(query)
      },
      handleCurrentChange(val) {
				this.pages.page = val
				let query = this.getQuery()
				query += `pageSize=${this.pages.pageSize}&page=${val}`
				this.getList(query)
			},
			handleSearch(){
				let query = this.getQuery()
				query += `pageSize=${this.pages.pageSize}&page=${this.pages.page}`
				this.getList(query)
			},
			handleDelete(row){
				console.log(row)
				let _this = this
				let template = `确定要删除编号：<span class="green-txt">【${row.name}】</span>吗？`
				this.$alert(template,"删除", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: async function(action,instance){
						if(action === "confirm"){//确认删除
							try{
								await switchboardIdentityDelete(row.id)
								let query = _this.getQuery()
								_this.getList(query)
								alert('删除成功')
							}catch(e){
								console.log(e)
							}
						}
						console.log(action,instance)
					}
				});
			},
			async getList(query){
				query = query ? query : null
				try{
					let data = await switchboardIdentity(query)
					let pages = {}
					pages.page = data.page
					pages.pageSize = data.pageSize
					pages.totalPages = data.totalPages
					pages.totalRecords = data.totalRecords
					this.pages = pages
					this.tableData = data.results
					this.$nextTick(function () {
						this.tableHeight = this.utils.getTableHeight()
					})
				}catch(e){
					console.log(e)
				}
			}
		},
	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>