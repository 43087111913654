import axios from '@/config/httpConfig'

// export function fetchPermission() {
//     return axios.get('/user/info')
// }

// // 获取用户列表
// export function getUserList() {
//     return axios.get('/user/list')
// }
// // 获取角色列表
// export function getRoleList() {
//     return axios.get('/role/list')
// }
// // 获取所有权限
// export function getAllPermissiion() {
//     return axios.get('/permission/all')
// }
// // 获取一级权限列表
// export function getFirstLevel() {
//     return axios.get('/permission/resource')
// }
// // 获取次级权限列表
// export function getNextLevel(id) {
//     return axios.get(`/permission/level?id=${id}`)
// }

//注册
export function getcode(data) { //注册获取手机验证码
    return axios.post('/common/user/registerCode', data)
}
export function register(data) { //注册
    return axios.post('/common/user/register', data)
}
//图形验证码
export function getverificationimage() {//获取图形验证码
    return axios.post('common/verificationImage/init')
}
export function validateimage(data) {//检测图片是否正确
    return axios.post('/common/verificationImage/check',data)
}
//登录
export function login(data) {//登录
    return axios.post('common/doLogin', data)
}
export function getlogincode(data) { //登录获取手机验证码
    return axios.post('/common/smsCode', data)
}
export function smslogin(data) { //短信登录
    return axios.post('/common/smsLogin', data)
}
export function logout() { //外蛋系统单个信息
    return axios.get(`/common/logout`)
}
//忘记密码
export function getPwdCode(data) { //忘记密码获取手机验证码
    return axios.post('/common/pwd/code', data)
}
export function pwdReset(data) { //忘记密码获取手机验证码
    return axios.post('/common/pwd/reset', data)
}



//外蛋系统管理
export function subsys() {//外蛋系统列表
    return axios.get('/admin/subSys/')
}
export function subsysShow(id) { //外蛋系统单个信息
    return axios.get(`/admin/subSys/show?id=${id}`)
}
export function subsysSave(data) { //添加
    return axios.post('/admin/subSys/save',data)
}
export function subsysDelete(id) { //删除
    return axios.delete(`/admin/subSys/delete?id=${id}`)
}
export function subsysupdate(data) { //更新
    return axios.put('/admin/subSys/update',data)
}
export function subsysUpdateKey(id) { //更新公钥和秘钥
    return axios.put(`/admin/subSys/updateKey?id=${id}`)
}
export function subsysClear(id) { //清除编号
    return axios.delete(`/admin/subSys/deletePrivatization?id=${id}`)
}
//超级管理员
export function adminShow() {
    return axios.get('/admin/admin')
}
export function adminSave(data) { //添加
    return axios.post('/admin/admin/save', data)
}
export function adminDelete(id) { //删除
    return axios.delete(`/admin/admin/delete?id=${id}`)
}

//用户管理
export function adminUser(query) {
    return axios.get(`/admin/user/?${query}`)
}
//编号管理
export function switchboardIdentity(query) {
    return axios.get(`/admin/switchboardIdentity/?${query}`)
}
export function switchboardIdentityAdd() {
    return axios.post('/admin​/switchboardIdentity​/save')
}
export function switchboardIdentityDelete(id) { //删除
    return axios.delete(`/admin/switchboardIdentity/delete?id=${id}`)
}
export function switchboardIdentityUpdate(data) {//修改手机号
    return axios.put(`/admin/user/change`, data)
}
//请求日志
export function weblog(query) {
    return axios.get(`/admin/weblog/?${query}`)
}
//公司管理
export function switchboard(query) {
    return axios.get(`/admin/switchboard/?${query}`)
}
// 微信登录
export function wxApp(query) {
    return axios.get(`/admin/wxApp/?${query}`)
}
export function wxAppShow(id) {
    return axios.get(`/admin/wxApp/show?id=${id}`)
}
export function wxAppAdd(data) {
    return axios.post(`/admin/wxApp/save`, data)
}
export function wxAppUpdate(data) {
    return axios.put(`/admin/wxApp/update`, data)
}
export function wxAppDelete(id) {
    return axios.delete(`/admin/wxApp/delete?id=${id}`)
}