<template>
<div v-show="showCaptcha">
	<div class="layyer"></div>
	<div class="dialog">
		<div class="com-img">
			<div class="comImageValidate rightValidate">
				<div class="imgBg">
					<div class="imgBtn">
						<img />
					</div>
					<span class="refresh">
						<img alt="" :src="comImageValidate.src">
					</span>
				</div>
				<div class="hkinnerWrap" @mouseup="mouseupCaptcha" style="height: 33px;position: relative">
					<span class="v_rightBtn"><em class="notSel">→</em></span>
					<span class="huakuai"
						style="display: block; font-size: 12px;line-height: 33px;color: #A9A9A9;">{{comImageValidate.btntxt}}</span>
					<input type="hidden" name="validX" />
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import { getverificationimage, validateimage } from '@/api/permission'
export default {
	props: {
		showCaptcha: Boolean
	},
	data() {
		return {
			comImageValidate: {
				btntxt: '向右滑动滑块填充拼图',
				imgBg:'',
				src:''
			},
		}
	},
	watch:{ //监听value的变化，进行相应的操作即可
		showCaptcha: {
			handler(newVal){ //a是value的新值，b是旧值
				if(newVal){
					this.validateImageInit()
				}
				return newVal
			},
			deep: true
		}
	},
	mounted() {
		this.dragImage()//初始化拖动等本地事件
		// this.validateImageInit()
	},
	methods: {
		refresh(){
			this.validateImageInit();
		},
		async validateImageInit(){
			try {
				let data = await getverificationimage()
				this.token = data.token
				this.y = data.y
				this.$(".huakuai").html("向右滑动滑块填充拼图");
				// let
				this.$(".imgBg").css("background",`red`);
				let url = ("data:image/jpg;base64," + data.sourceImage).replace(/\s*/g,"")
				this.$(".imgBg").css("background",'#fff url("'+url+'")');
				this.$(".imgBg").css("background-size","100% 100%")
				this.$(".imgBtn").css('top','5px');
				this.$(".imgBtn").find("img").attr("src","data:image/png;base64,"+data.newImage)
				// tokenId=data.token;
				let y = data.y;
				console.log(y)
				this.$(".hkinnerWrap").removeClass("red green");
				this.$(".v_rightBtn").css("left",0);
				this.$(".imgBtn").css("left",0);
			} catch (e) {
				console.log(e)
				// this.validateImageInit();
			}
		},
		dragImage(){
			let _this = this
			this.$('.v_rightBtn').on({
				mousedown: function(e) {
					_this.$(".huakuai").html("");
					_this.$(".hkinnerWrap").removeClass("red green")
					var el = _this.$(this);
					console.log(el,_this)
					var os = el.offset();
					var dx = e.pageX - os.left;
					//_this.$(document)
					_this.$(this).parents(".hkinnerWrap").off('mousemove');
					_this.$(this).parents(".hkinnerWrap").on('mousemove', function(e) {
						var newLeft=e.pageX - dx;
						el.offset({
							left: newLeft
						});
						var newL=parseInt(_this.$(".v_rightBtn").css("left"));
						if(newL<=0){ newL=0; }else if (newL>=298){
							newL=306;
						}
						_this.$(".v_rightBtn").css("left",newL+"px");
						_this.$(".imgBtn").offset({
							left: newLeft
						});
						_this.$(".imgBtn").css("left",newL+"px")
					}).on('mouseup', function(e) {
						console.log(e)
						_this.$(this).off('mousemove');
					})
				}
			})
		},
		mouseupCaptcha(){
			let _this = this.$('.v_rightBtn')
			_this.parents(".hkinnerWrap").off('mousemove');
			var l=_this.css("left");
			if(l.indexOf("px")!=-1){
			l=l.substring(0,l.length-2);
			}
			var x = l;
			console.log(x)
			this.submitDate(x)
		},
		async submitDate(x){
			let y = this.y
			let params = {
				token: this.token,
				X:parseInt(x),
				Y:y
			}
			try{
				let data = await validateimage(params)
				console.log(data)
				this.$(".hkinnerWrap").addClass("green").removeClass("red");
				this.$(".hkinnerWrap input[name='validX']").val(x);
				this.$("#X").val(x);
				this.$("#Y").val(y);
				// this.showCaptcha = false
				this.respCaptcha(data)
				// this.getCode(this.loginForm.login,data)
			} catch (e) {
				console.log(e)
			}
		},
		respCaptcha(captcha){
			this.$emit('captcha', captcha)
		}
	},
}
</script>
<style>
	.rightValidate {
		width: 350px;
		margin: 0px auto;
		position: relative;
		line-height: 33px;
		height: 33px;
		text-align: center;
		z-index: 99;
	}

	.v_rightBtn {
		position: absolute;
		left: 0;
		top: 0;
		height: 33px;
		width: 40px;
		background: #ddd;
		cursor: pointer;
	}

	.imgBtn {
		width: 44px;
		height: 214px;
		position: absolute;
		left: 0;
	}

	.imgBtn img {
		height: 100%;
		z-index: 99;
	}

	.imgBg {
		/* position: absolute; */
		bottom: 35px;
		width: 350px;
		height: 214px;
		box-shadow: 0px 4px 8px #3C5476;
		/* display: none; */
		z-index: 9;
		background-size: 100% 100%;
	}

	.hkinnerWrap {
		border: 1px solid #eee;
		background: #fff;
	}

	.green {
		border-color: #34C6C2 !important;
	}

	.green .v_rightBtn {
		background: #34C6C2;
		color: #fff;
	}

	.red {
		border-color: red !important;
	}

	.red .v_rightBtn {
		background: red;
		color: #fff;
	}

	.refresh {
		position: absolute;
		width: 30px;
		height: 30px;
		right: 4px;
		top: 4px;
		font-size: 12px;
		color: #fff;
		text-shadow: 0px 0px 9px #333;
		cursor: pointer;
		display: none;
	}

	.notSel {
		user-select: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-webkit-touch-callout: none;
	}
</style>