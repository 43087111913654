export default {
	getTableHeight(){
		let tableHeight = '100px'
		this.clientHeight = `${document.documentElement.clientHeight}`;
		let welcome = document.querySelector('.welcome')
		let nav = document.querySelector('.nav')
		let subNav = document.querySelector('.sub-nav')
		let search = document.querySelector('.search')
		let welcomeHeight = welcome.clientHeight || welcome.offsetHeight;
		let navHeight = nav.clientHeight || nav.offsetHeight;
		let subNavHeight = subNav.clientHeight || subNav.offsetHeight;
		let searchHeight = search.clientHeight || search.offsetHeight;
		tableHeight = this.clientHeight - welcomeHeight - navHeight - subNavHeight - searchHeight - 20
		tableHeight = tableHeight + 'px'
		return tableHeight
	}
}
// export function recursionRouter(userRouter = [], allRouter = []) {
// 	var realRoutes = allRouter
// 		.filter(item => userRouter.includes(item.name))
// 		.map(item => ({
// 			...item,
// 			children: item.children ?
// 				recursionRouter(userRouter, item.children) :
// 				null
// 		}))
// 	return realRoutes
// }