<template>
	<div>
		<div class="sub-nav clearfix">
			<div class="l">
				<div class="sub-nav-common sub-nav-title">日志列表</div>
			</div>
			<div class="r">
				<el-pagination
					background
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="1"
					:page-sizes="[20, 30, 50]"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.totalRecords">
				</el-pagination>
			</div>
		</div>
		<div class="search">
			<div class="item clearfix">
				<div class="label clearfix l">
					<span class="label-txt l">url：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.url">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">httpMethod：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.httpMethod">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">ip：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.ip">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">headers：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.headers">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">parameter：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.parameter">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">userAgent：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.userAgent">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">startTime：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.startTime">
						</el-input>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<el-table
			:data="tableData"
			:max-height="tableHeight ? tableHeight : 0"
			style="width: 100%">
				<el-table-column label="序号" min-width="50">
					<template scope="scope">
						{{pages.pageSize*(pages.page-1)+scope.$index+1}}
					</template>
				</el-table-column>
				<el-table-column prop="classMethod" label="classMethod" width="180">
				</el-table-column>
				<el-table-column prop="httpMethod" label="httpMethod" width="180">
				</el-table-column>
				<el-table-column prop="ip" label="ip" width="180">
					<!-- <template scope="scope">
						<div>{{scope.row.status}}</div>
					</template> -->
				</el-table-column>
				<el-table-column prop="responseStatus" label="responseStatus" width="180">
				</el-table-column>
				<el-table-column prop="spendTime" label="spendTime" width="180">
				</el-table-column>
				<el-table-column prop="startTime" label="startTime" width="180">
				</el-table-column>
				<el-table-column prop="url" label="url" width="220">
				</el-table-column>
				<el-table-column prop="parameter" label="parameter" min-width="380">
				</el-table-column>
				<el-table-column prop="userAgent" label="userAgent" min-width="380">
				</el-table-column>
				<el-table-column prop="headers" label="headers" min-width="1000">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	import { weblog } from '@/api/permission'
	export default {
		data() {
			return {
				tableHeight: '',
				search: {
					url: '',
					httpMethod: '',
					ip: '',
					parameter: '',
					headers: '',
					userAgent: '',
					startTime: '',
				},
				pages: {
					page: 1,
					pageSize: 20,
				},
				current: 2,
				input2: '',
				tableData: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getQuery(){
				let query = ''
				let search = this.search
				for(var i in search) {
					if(search[i]){
						query += `&${i}=${search[i]}`
					}
				}
				return `${query}&`
			},
			handleSizeChange(val) {
				this.pages.pageSize = val
				let query = this.getQuery()
				query += `pageSize=${val}&page=${this.pages.page}`
				this.getList(query)
      },
      handleCurrentChange(val) {
				this.pages.page = val
				let query = this.getQuery()
				query += `pageSize=${this.pages.pageSize}&page=${val}`
				this.getList(query)
			},
			handleSearch(){
				let query = this.getQuery()
				query += `pageSize=${this.pages.pageSize}&page=${this.pages.page}`
				this.getList(query)
			},
			async getList(query){
				query = query ? query : ''
				try{
					let data = await weblog(query)
					let pages = {}
					pages.page = data.page
					pages.pageSize = data.pageSize
					pages.totalPages = data.totalPages
					pages.totalRecords = data.totalRecords
					this.pages = pages
					this.tableData = data.results
					this.$nextTick(function () {
						this.tableHeight = this.utils.getTableHeight()
					})
					console.log(data)
				}catch(e){
					console.log(e)
				}
			},
		},
	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>