<template>
	<div>
		<Header />
		<Nav :current=6 />
		<router-view></router-view>
	</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
export default {
	components: {
		Header,
		Nav
	},
	data() {
		return {
		}
	},
	mounted() {

	}
}
</script>
<style scoped lang="less">
	div {
		font-size: 20px;
	}
</style>
