<template>
	<div>
		<div class="sub-nav clearfix">
			<div class="l">
				<div class="sub-nav-common sub-nav-title">日志列表</div>
			</div>
			<div class="r">
				<el-pagination
					background
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="1"
					:page-sizes="[20, 30, 50]"
					:page-size="pages.pageSize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pages.totalRecords">
				</el-pagination>
			</div>
		</div>
		<div class="search">
			<div class="item clearfix">
				<div class="label clearfix l">
					<span class="label-txt l">certification：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.certification">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">company：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.company">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">createdAt：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.createdAt">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">id：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.id">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">mobile：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.mobile">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">status：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.status">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">userId：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.userId">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">vip：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" @keyup.enter.native="handleSearch" v-model="search.vip">
						</el-input>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<el-table
			:data="tableData"
			style="width: 100%"
			:max-height="tableHeight ? tableHeight : 0">
				<el-table-column label="序号" min-width="50">
					<template scope="scope">
						{{pages.pageSize*(pages.page-1)+scope.$index+1}}
					</template>
				</el-table-column>
				<el-table-column prop="certification" label="certification" width="180">
				</el-table-column>
				<el-table-column prop="company" label="公司" width="180">
				</el-table-column>
				<el-table-column prop="createdAt" label="创建时间" width="180">
				</el-table-column>
				<el-table-column prop="id" label="id" width="280">
				</el-table-column>
				<el-table-column prop="mobile" label="手机号码" width="180">
				</el-table-column>
				<el-table-column prop="status" label="status">
					<template scope="scope">
						{{scope.row.status}}
					</template>
				</el-table-column>
				<el-table-column prop="userId" label="userId" width="180">
				</el-table-column>
				<el-table-column prop="vip" label="vip" width="180">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	import { switchboard } from '@/api/permission'
	export default {
		data() {
			return {
				tableHeight: '',
				search: {
					certification: '',
					company: '',
					createdAt: '',
					id: '',
					mobile: '',
					status: '',
					userId: '',
					vip: '',
				},
				pages: {
					page: 1,
					pageSize: 20,
				},
				current: 2,
				input2: '',
				tableData: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			getQuery(){
				let query = ''
				let search = this.search
				for(var i in search) {
					if(search[i]){
						query += `&${i}=${search[i]}`
					}
				}
				return `${query}&`
			},
			handleSizeChange(val) {
				this.pages.pageSize = val
				let query = this.getQuery()
				query += `pageSize=${val}&page=${this.pages.page}`
				this.getList(query)
      },
      handleCurrentChange(val) {
				this.pages.page = val
				let query = this.getQuery()
				query += `pageSize=${this.pages.pageSize}&page=${val}`
				this.getList(query)
			},
			handleSearch(){
				let query = this.getQuery()
				query += `pageSize=${this.pages.pageSize}&page=${this.pages.page}`
				this.getList(query)
			},
			async getList(query){
				query = query ? query : null
				try{
					let data = await switchboard(query)
					let pages = {}
					pages.page = data.page
					pages.pageSize = data.pageSize
					pages.totalPages = data.totalPages
					pages.totalRecords = data.totalRecords
					this.pages = pages
					this.tableData = data.results
					this.$nextTick(function () {
						this.tableHeight = this.utils.getTableHeight()
					})
				}catch(e){
					console.log(e)
				}
			},
		},
	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>