<template>
  <header>
		<div class="bl">
			<div class="welcome">欢迎<span class="yellow-txt">[{{username}}]</span><span class="logout" @click="logout">退出</span>
			</div>
		</div>
	</header>
</template>

<script>
// import { logout } from '@/api/permission'
export default {
	name: 'Header',
  props: {
		// mobile: String,
	},
	data() {
		return {
			baseUrl: process.env.NODE_ENV === 'production' ? '/' : '/api',
			username: localStorage.getItem('username')
		}
	},
	created() {
		console.log( process.env.NODE_ENV)
	},
	methods: {
		logout() {
			console.log(this)
			window.location.href = `${this.baseUrl}common/logout`
		}
	},
}
</script>
<style scoped lang="less">
	.logout {
		margin: 5px;
		font-size: 16px;
		&:hover {
			color: #55C4B8;
			cursor: pointer;
		}
	}

</style>

