<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">详情</div>
		</div>
		<div class="content">
			<div class="bl lr-form" v-if="formData">
				<el-form label-width="100px" class="demo-ruleForm">
					<el-form-item label="系统外蛋名称">
						<el-input readonly v-model="formData.name"></el-input>
					</el-form-item>
					<el-form-item label="主体手机号">
						<el-input readonly v-model="formData.mobile"></el-input>
					</el-form-item>
					<el-form-item label="系统域名" prop="domain">
						<el-input readonly v-model="formData.domain"></el-input>
					</el-form-item>
					<el-form-item label="开始时间">
						<el-col :span="11">
							<el-form-item prop="start">
								<el-date-picker readonly type="datetime" placeholder="选择开始时间" v-model="formData.start" style="width: 100%;">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-form-item>
					<el-form-item label="结束时间">
						<el-col :span="11">
							<el-form-item prop="dateEnd">
								<el-date-picker readonly type="datetime" placeholder="选择结束时间" v-model="formData.dateEnd" style="width: 100%;">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-form-item>
					<el-form-item label="子系统logo地址">
						<el-input readonly v-model="formData.img"></el-input>
					</el-form-item>
					<el-form-item label="公钥" prop="publicKey">
						<el-input readonly v-model="formData.publicKey"></el-input>
						<span class="l copy"
						v-clipboard:copy="formData.publicKey"
						v-clipboard:success="onCopy"
						v-clipboard:error="onError">复制公钥</span>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-input readonly v-model="formData.status"></el-input>
					</el-form-item>
					<el-form-item label="是否主体" prop="mainstay">
						<el-radio-group v-model="formData.mainstay" disabled>
							<el-radio :label=true>是</el-radio>
							<el-radio :label=false>否</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item class="submit">
						<el-button @click="toPath">返回</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import { subsysShow } from '@/api/permission'
	export default {
		data() {
			return {
				formData: null,
			}
		},
		created() {
			if(this.$route.query.id){//编辑
				this.id = this.$route.query.id
				this.getItem(this.$route.query.id)
			}
		},
		methods: {
			onCopy() {
				this.$message({
					message: '复制成功！',
					type: 'success'
				});
			},
			onError(e) {
				alert('复制失败',e)
			},
			toPath(){
				this.$router.push('/subsys')
			},
			async getItem(id){
				try{
					let data = await subsysShow(id)
					this.formData = data
				}catch(e){
					console.log(e)
				}
			},
		},

	}
</script>
<style scoped lang="less">
	@import "../../../styles/_utils.less";
	@import "../../../styles/_variables.less";
  .content input.el-input__inner {
		background: red;
	}
	.copy {
		display: inline-block;
		position: absolute;
		top: 5px;
		left: 240px;
		background: @green;
		padding: 0 10px;
		border-radius: 3px;
		height: 30px;
		line-height: 30px;
		color: @white;
		&:hover {
			cursor: pointer;
			opacity: .9;
		}
	}
</style>