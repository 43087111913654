<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">微信App信息</div>
			<div class="sub-nav-common sub-nav-btn" @click="toPath('add')">添加</div>
		</div>
		<div class="content">
			<el-table :data="tableData" stripe style="width: 100%">
				<el-table-column prop="id" label="id" width="280">
				</el-table-column>
				<el-table-column prop="secret" label="secret" width="280">
				</el-table-column>
				<el-table-column prop="createdAt" label="创建时间" width="180">
				</el-table-column>
				<el-table-column prop="status" label="状态" width="220">
					<template scope="scope">
						<div>{{scope.row.status}}</div>
					</template>
					
				</el-table-column>
				<el-table-column prop="appId" label="appId" width="180">
				</el-table-column>
				<el-table-column prop="name" label="名称" width="180">
				</el-table-column>
				<el-table-column label="操作" width="280">
					<template scope="scope">
						<div class="handle">
							<span class="link" @click="toPath('details',scope.row)">详情</span>
							<span class="link" @click="toPath('edit',scope.row)">编辑</span>
							<span class="link" @click="handleDelete(scope.row)">删除</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	import {
		wxApp,
		wxAppDelete
	} from '@/api/permission'
	export default {
		data() {
			return {
				input2: '',
				tableData: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			async getList() {
				try {
					let data = await wxApp()
					this.tableData = data.results
					console.log(data)
				} catch (e) {
					console.log(e)
				}
			},
			handleDelete(row) {
				console.log(row)
				let _this = this
				let template =
					`确定要删除<span class="green-txt">【${row.name}】</span>吗？`
				this.$alert(template, "删除", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: async function (action, instance) {
						if (action === "confirm") { //确认删除
							try {
								await wxAppDelete(row.id)
								alert('删除成功')
								_this.getList()
							} catch (e) {
								console.log(e)
							}
						}
						console.log(action, instance)
					}
				});
			},
			copy(siyao) {
				let _this = this
				this.$copyText(siyao).then(function (e) {
					console.log(e)
					_this.$message({
						message: '复制成功！',
						type: 'success'
					});
				}, function (e) {
					alert('Can not copy')
					console.log(e)
				})
			},
			toPath(type, row) {
				let path = ''
				let query = {}
				if (type == "add") { //添加外蛋系统列表
					path = '/wxlogin/add'
				} else if (type == "systemUser") { //
					path = '/wxlogin/user'
					query = {
						id: row.id || ''
					}
				} else if (type == "details") {
					path = '/wxlogin/details'
					query = {
						id: row.id
					}
				} else if (type == "edit") {
					path = '/wxlogin/add'
					query = {
						id: row.id
					}
				}
				this.$router.push({
					path,
					query,
				}); //传参
			}
		},
	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>