<template>
  <header>
		<div class="bl">
			<div class="nav clearfix">
				<div class="logo l"><img src="../images/logo.png"></div>
				<div class="nav-btn l">
					<el-row type="flex" class="row-bg" justify="center">
						<el-col
						:span="7"
						v-for="(item, index) in list"
						:key="index">
							<div @click="toPath(item.path,index)" class="grid-content bg-purple" :class="current == index ? 'current':''">{{item.name}}
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</header>
</template>
<script>
export default {
  name: 'Nav',
  props: {
		current: Number
		// username: String,
	},
	data() {
		return {
			list: [{
					name: '外蛋系统管理',
					path: '/subsys'
				},{
					name: '超级管理员',
					path: '/admin'
				},{
					name: '用户管理',
					path: '/manage'
				},{
					name: '编号管理',
					path: '/identifier'
				},{
					name: "日志管理",
					path: '/weblog'
				},{
					name: "公司管理",
					path: '/company'
				},{
					name: "微信App信息",
					path: '/wxlogin'
				}
			]
		}
	},
	created() {
		console.log(this)
	},
	methods: {
		toPath(path,index) {
			console.log(path,index)
			this.$router.push({
				path: path,
				index: index
			});
		}
	},
}
</script>

<style scoped lang="less">

</style>
