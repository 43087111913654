<template>
<div class="login">
	<Captcha :showCaptcha="isShow" @captcha="captcha" />
	<div class="bg" :class="psFlag ? 'psBg' :''"></div>
	<div class="login-container" :class="psFlag ? 'ps-login-container' :''">
		<div class="login-logo" v-if="!psFlag">
			<img src="../../images/logo_regi.png">
		</div>
		<el-form class="login-form" autoComplete="on" :class="psFlag ? 'ps-login-form' :''"  :model="loginForm" :rules="loginRules" ref="loginForm"
			label-position="left">
			<!-- <div class="ps-title" v-if="psFlag">普盛很能猜系统</div> -->
			<div class="ps-title" v-if="psFlag">
				<!-- 判断psFlag等于true是普盛很能猜的时候普盛logo显示，否则不显示 -->
				<img src="../../images/ps-logo.png">
				<div>普盛很能猜系统</div>
			</div>
			<el-form-item prop="login">
				<i class="ps-phone-icon" v-if="psFlag"></i>
				<el-input name="login" type="text" :class="psFlag ? 'ps-phone' :''" maxLength="11" v-model="loginForm.login" autoComplete="on" placeholder="请输入手机号" />
			</el-form-item>
			<el-form-item prop="code">
				<el-input style="width: 180px;float: left;" :style="[{width:(psFlag ? '230px' : '180px')}]" maxLength="4" name="code" type="text" v-model="loginForm.code" autoComplete="on"
					placeholder="请输入验证码" />
				<div v-show="codeTime" @click="getCaptcha" class="r codeBtn">发送验证码</div>
				<div v-show="!codeTime" class="r codeBtn gray">{{countTime}}s</div>
			</el-form-item>
			<!-- 为了解决进入页面不做校验问题，增加了两行input框开始 -->
			<el-form-item prop="pwd" style="position:fixed;bottom:-99999px">
				<el-input name="pwd" type="password" style="position:fixed;bottom:-99999px" placeholder="请输入密码"></el-input>
			</el-form-item>
			<!-- 为了解决进入页面不做校验问题，增加了两行input框结束 -->
			<el-form-item prop="pwd">
				<i class="ps-pwd-icon" v-if="psFlag"></i>
				<el-input name="pwd" :class="psFlag ? 'ps-pwd' :''" :type="pwdType" @keyup.enter.native="login" v-model="loginForm.pwd" autoComplete="on"
					placeholder="请输入密码"></el-input>
			</el-form-item>
			<el-form-item prop="checkPass">
				<i class="ps-pwd-icon" v-if="psFlag"></i>
				<el-input type="password" :class="psFlag ? 'ps-pwd' :''" v-model="loginForm.checkPass" placeholder="请再次输入密码" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" style="width:100%;" :loading="loading" @click.native.prevent="pwdResetSubmit('loginForm')">
					同意协议并注册
				</el-button>
			</el-form-item>
			<!-- <div class="tips">登录</div> -->
		</el-form>
		<div class="log-foot clearfix" :class="psFlag ? 'ps-log-foot' :''">
			<div class="regi">
				<div class="bl l">
					<span style="cursor:default;">已有账号，<span @click="toPath('login')" class="green-txt">登录</span></span>
				</div>
				<div class="bl r">
					<span @click="toPath('agreement')" class="span green-txt">《服务协议》</span>
					<span @click="toPath('privacyPolicy')" class="span green-txt">《隐私协议》</span>
				</div>
			</div>
		</div>
		<!-- 判断psFlag等于true是普盛很能猜的时候普盛logo显示，否则不显示 -->
		<!-- <div class="ps-login-logo" v-if="psFlag">
			<img src="../../images/ps-logo.png">
		</div> -->
	</div>
</div>
</template>

<script>
	import Captcha from '@/components/Captcha.vue'
	import { register, getcode } from '@/api/permission'
	export default {
		components: {
			Captcha,
		},
		data() {
			const validateUsername = (rule, value, callback) => {
				if (value.length < 11) {
					callback(new Error('请输入正确的手机号'))
				} else {
					callback()
				}
			}
			const validateCode = (rule, value, callback) => {
				if (value.length < 4) {
					callback(new Error('请输入正确的验证码'))
				} else {
					callback()
				}
			}
			const validatePass = (rule, value, callback) => {
				if (value.length < 6) {
					callback(new Error('密码不能小于6位'))
				} else {
					callback()
				}
			}
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.loginForm.pwd) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				returnURL: '',
				countTime: '',
				codeTime: true,//倒计时
				timer: null,
				isShow: false,
				loginForm: {
					login: '',
					code: '',
					pwd: '',
					checkPass: ''
				},
				loginRules: {
					login: [
						{
							required: true,
							trigger: 'blur',
							validator: validateUsername
						}
					],
					code: [
						{
							required: true,
							trigger: 'blur',
							validator: validateCode
						}
					],
					pwd: [
						{ required: true, trigger: 'blur', validator: validatePass }
					],
					checkPass: [
						{ required: true, validator: validatePass2, trigger: 'blur' }
					],
				},
				loading: false,
				pwdType: 'password',
				psFlag:false,//区分是普盛很能猜还是saas平台很能猜登录页面，false是saas平台，true是普盛很能猜系统
			}
		},
		mounted() {
			this.returnURL = this.$route.query.returnURL
			this.loginForm.login = this.$route.query.mobile
			console.log(this.$route)
			//从首页输入手机号进来，自动填充
			if (this.$route.query.login) {
				this.loginForm.login = this.$route.query.login
			}
			// 000新增判断是saas平台还是普盛很能猜
			let url = window.location.href
			if(url.indexOf("login.pushengfood.com") == -1){
				this.psFlag = false
			}else{
				this.psFlag = true
			}
		},
		methods: {
			toPath(url){
				this.$router.push({path:`/${url}`})
			},
			captcha(captcha){//打开图形码
				this.isShow = false
				this.getCode(captcha)
			},
			async getCode(captcha){
				let params = {
					mobile: this.loginForm.login,
					captcha: captcha
				}
				try {
					await getcode(params)
					this.codeTimer()
					this.$message({
						message: '发送成功！',
						type: 'success'
					});
				}catch(e){
					console.log(e)
				}
			},
			codeTimer(){
				const TIME_COUNT = 120;
				if (!this.timer) {
					this.countTime = TIME_COUNT;
					this.codeTime = false;
					this.timer = setInterval(() => {
					if (this.countTime > 0 && this.countTime <= TIME_COUNT) {
						this.countTime--;
						} else {
						this.codeTime = true;
						clearInterval(this.timer);
						this.timer = null;
						}
					}, 1000)
				}
			},
			async getCaptcha(){
				let mobile = this.loginForm.login || ''
				if(mobile.length !== 11){
					this.$message({
						message: '请输入正确的手机号！',
						type: 'warning'
					});
					return
				}else {
					this.isShow = true
				}
			},
			// 同意并注册
			async pwdResetSubmit(params){
				let that = this
				let data = {
					mobile: this.loginForm.login,
					code: this.loginForm.code,
					pwd: this.loginForm.pwd,
					checkPwd: this.loginForm.checkPass,
					returnURL: this.returnURL
				}
				that.$refs[params].validate((valid) =>{
					if(valid){
						register(data).then( () =>{
							this.$message({
								message: '注册成功！',
								type: 'success'
							});
							this.$router.push({
								path: '/login',
								query: {
									returnURL: this.returnURL,
									login: this.loginForm.login
								}
							})
						})
						
					}
				})
			},
			async register(){
				let params = {
					mobile: this.loginForm.login,
					code: this.loginForm.code,
					pwd: this.loginForm.pwd,
					checkPwd: this.loginForm.checkPass,
					returnURL: this.returnURL
				}
				try{
					await register(params)
					this.$message({
						message: '注册成功！',
						type: 'success'
					});
					this.$router.push({
						path: '/login',
						query: {
							returnURL: this.returnURL,
							login: this.loginForm.login
						}
					})
				}catch(e){
					console.log(e)
				}
			}
		}
	}
</script>
<style scoped lang="less">
	@import "./login.less";
</style>
