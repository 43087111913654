<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">{{type == 'add'?'添加': '编辑'}}外蛋</div>
		</div>
		<div class="content">
			<div class="bl lr-form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="系统外蛋名称" prop="name">
						<el-input v-model="ruleForm.name"></el-input>
					</el-form-item>
					<el-form-item label="主体手机号" prop="mobile">
						<el-input v-model="ruleForm.mobile"></el-input>
					</el-form-item>
					<el-form-item label="系统域名" prop="domain">
						<el-input v-model="ruleForm.domain"></el-input>
					</el-form-item>
					<el-form-item label="开始时间">
						<el-col :span="11">
							<el-form-item prop="start">
								<el-date-picker type="datetime" placeholder="选择开始时间" v-model="ruleForm.start" style="width: 100%;">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-form-item>
					<el-form-item label="结束时间">
						<el-col :span="11">
							<el-form-item prop="dateEnd">
								<el-date-picker type="datetime" placeholder="选择结束时间" v-model="ruleForm.dateEnd" style="width: 100%;">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-form-item>
					<el-form-item label="子系统logo地址" prop="img">
						<el-input v-model="ruleForm.img"></el-input>
					</el-form-item>
					<el-form-item label="回调方法" prop="callback">
						<el-input v-model="ruleForm.callback"></el-input>
					</el-form-item>
					<el-form-item label="是否主体" prop="mainstay">
						<el-radio-group v-model="ruleForm.mainstay" :disabled="type == 'add'?false:true">
							<el-radio :label=true>是</el-radio>
							<el-radio :label=false>否</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item class="submit">
						<el-button v-if="type == 'add'" type="primary" @click="submitForm('ruleForm')">添加</el-button>
						<el-button v-else type="primary" @click="editForm('ruleForm')">编辑</el-button>
						<el-button @click="toPath">返回</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import { subsysSave, subsysShow, subsysupdate } from '@/api/permission'
	export default {
		data() {
			return {
				siyao: '',
				type: 'add',
				id: '',
				ruleForm: {
					name: '',
					domain: '',
					mobile:'',
					start: '',
					end: '',
					img: '',
					mainstay: false,
				},
				rules: {
					name: [
						{ required: true, message: '请输入系统外蛋名称', trigger: 'blur' },
					],
					domain: [
						{ required: true, message: '请输入系统名称', trigger: 'blur' },
					],
					mobile: [
						{ required: true, message: '请输入主体手机号', trigger: 'blur' },
					]
				}
			}
		},
		created() {
			console.log(this.$route)
			if(this.$route.query.id){//编辑
				this.type = "edit"
				this.id = this.$route.query.id
				this.getItem(this.$route.query.id)
			}
		},
		methods: {
			toPath(){
				this.$router.push('/subsys')
			},
			async getItem(id){
				try{
					let data = await subsysShow(id)
					this.ruleForm.domain = data.domain
					this.ruleForm.name = data.name
					this.ruleForm.mobile = data.mobile
					this.ruleForm.start = data.start
					this.ruleForm.end = data.end
					this.ruleForm.img = data.img
					this.ruleForm.mainstay = data.mainstay
				}catch(e){
					console.log(e)
				}
			},
			copy(siyao) {
				let _this = this
				this.$copyText(siyao).then(function (e) {
					console.log(e)
					_this.$message({
						message: '复制成功！',
						type: 'success'
					});
				}, function (e) {
					alert('Can not copy')
					console.log(e)
				})
			},
			reset(siyao){//重置私钥
				let _this = this
				let template = `私钥：<span style="color: #FFBA1A;word-break: break-all;">${siyao}<span>`
				this.$alert(template,"温馨提示", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '复制',
					callback: function(action,instance){
						console.log(action,instance)
						_this.$router.push('/subsys')
						_this.copy(siyao)
					}
				});
			},
			editForm(formName){
				this.$refs[formName].validate(async(valid) => {
					if (valid) {
						try{
							this.ruleForm.id = this.id
							await subsysupdate(this.ruleForm)
							this.$message({
								message: '编辑成功！',
								type: 'success'
							});
							this.$router.push('/subsys')


							// this.reset(data)
						}catch(e){
							console.log(e)
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			submitForm(formName) {
				this.$refs[formName].validate(async(valid) => {
					if (valid) {
						try{
							let data = await subsysSave(this.ruleForm)
							this.reset(data)
						}catch(e){
							console.log(e)
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
		},

	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>