<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">{{type == 'add'?'添加': '编辑'}}微信App信息</div>
		</div>
		<div class="content">
			<div class="bl lr-form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="secret" prop="secret">
						<el-input v-model="ruleForm.secret"></el-input>
					</el-form-item>
					<el-form-item label="appId" prop="appId">
						<el-input v-model="ruleForm.appId"></el-input>
					</el-form-item>
					<el-form-item label="name" prop="name">
						<el-input v-model="ruleForm.name"></el-input>
					</el-form-item>
					<el-form-item class="submit">
						<el-button v-if="type == 'add'" type="primary" @click="submitForm('ruleForm')">添加</el-button>
						<el-button v-else type="primary" @click="editForm('ruleForm')">编辑</el-button>
						<el-button @click="toPath">返回</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import { wxAppAdd, wxAppShow, wxAppUpdate } from '@/api/permission'
	export default {
		data() {
			return {
				siyao: '',
				type: 'add',
				id: '',
				ruleForm: {
					secret: '',
					appId: '',
					name:'',
				},
				rules: {
					secret: [
						{ required: true, message: '请输入secret', trigger: 'blur' },
					],
					appId: [
						{ required: true, message: '请输入appId', trigger: 'blur' },
					],
					name: [
						{ required: true, message: '请输入name', trigger: 'blur' },
					]
				}
			}
		},
		created() {
			console.log(this.$route)
			if(this.$route.query.id){//编辑
				this.type = "edit"
				this.id = this.$route.query.id
				this.getItem(this.$route.query.id)
			}
		},
		methods: {
			toPath(){
				this.$router.push('/wxlogin')
			},
			async getItem(id){
				try{
					let data = await wxAppShow(id)
					this.ruleForm.secret = data.secret
					this.ruleForm.appId = data.appId
					this.ruleForm.name = data.name
				}catch(e){
					console.log(e)
				}
			},
			editForm(formName){
				this.$refs[formName].validate(async(valid) => {
					if (valid) {
						try{
							this.ruleForm.id = this.id
							await wxAppUpdate(this.ruleForm)
							this.$message({
								message: '编辑成功！',
								type: 'success'
							});
							this.$router.push('/wxlogin')
						}catch(e){
							console.log(e)
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			submitForm(formName) {
				let _this = this
				this.$refs[formName].validate(async(valid) => {
					if (valid) {
						try{
							let data = await wxAppAdd(this.ruleForm)
							console.log(data)
							this.$message({
								message: '保存成功！',
								type: 'success'
							});
							_this.$router.push('/wxlogin')
						}catch(e){
							console.log(e)
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
		},

	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>