<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">外蛋系统列表</div>
			<div class="sub-nav-common sub-nav-btn" @click="toPath('add')">添加</div>
		</div>
		<div class="content">
			<el-table :data="tableData" stripe style="width: 100%">
				<el-table-column prop="name" label="外蛋系统名称" width="180">
				</el-table-column>
				<el-table-column prop="domain" label="系统域名" width="280">
				</el-table-column>
				<el-table-column prop="mobile" label="手机号码" width="180">
				</el-table-column>
				<el-table-column prop="count" label="外蛋内使用人数" width="180">
					<template scope="scope">
						<div class="link" @click="toPath('systemUser',scope.row)">{{scope.row.count?scope.row.count:0}}</div>
						<!-- <div class="link" @click="toPath('systemUser',scope.row)">{{scope.row.count?scope.row.count:0}}</div> -->
					</template>
				</el-table-column>
				<el-table-column prop="callback" label="回调方法" width="180">
				</el-table-column>
				<el-table-column prop="start" label="开始时间" width="220">
				</el-table-column>
				<el-table-column prop="end" label="结束时间" width="180">
				</el-table-column>
				<el-table-column prop="mainstay" label="主体" width="180">
					<template scope="scope">{{scope.row.mainstay}}</template>
				</el-table-column>
				<el-table-column prop="status" label="状态">
				</el-table-column>
				<el-table-column label="操作" width="350" fixed="right">
					<template scope="scope">
						<div class="handle">
							<span class="link" @click="toPath('details',scope.row)">详情</span>
							<span class="link" @click="reset(scope.row)">重置私钥</span>
							<span class="link" @click="toPath('edit',scope.row)">编辑</span>
							<span class="link" @click="toPath('systemUser',scope.row)">维护</span>
							<span class="link" @click="handleDelete(scope.row)">删除</span>
							<span class="link" @click="handleClear(scope.row)">清除编号</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	import { subsys, subsysDelete, subsysUpdateKey, subsysClear} from '@/api/permission'
	export default {
		data() {
			return {
				input2: '',
				tableData: []
			}
		},
		created() {
			this.getList()
		},
		methods: {
			async getList(){
				try{
					let data = await subsys()
					this.tableData = data.results
					console.log(data)
				}catch(e){
					console.log(e)
				}
			},
			handleClear(row){
				let _this = this
				console.log(row)
				let template = `确定要清除<span class="green-txt">【${row.name}】</span>系统下的<span class="orange-txt">【${row.count}】</span>个编号吗？清除之后不可恢复！`
				this.$alert(template,"清除", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: async function(action,instance){
						if(action === "confirm"){//确认删除
							// console.log(subsysClear)
							try{
								await subsysClear(row.id)
								alert('清除成功')
								_this.getList()
							}catch(e){
								console.log(e)
							}
						}
						console.log(action,instance)
					}
				});
			},
			handleDelete(row){
				console.log(row)
				let _this = this
				let template = `确定要删除<span class="green-txt">【${row.name}】</span>及<span class="orange-txt">【${row.count}】</span>个编号吗？`
				this.$alert(template,"删除", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					showCancelButton: true,
					callback: async function(action,instance){
						if(action === "confirm"){//确认删除
							try{
								await subsysDelete(row.id)
								alert('删除成功')
								_this.getList()
							}catch(e){
								console.log(e)
							}
						}
						console.log(action,instance)
					}
				});
			},
			copy(siyao) {
				let _this = this
				this.$copyText(siyao).then(function (e) {
					console.log(e)
					_this.$message({
						message: '复制成功！',
						type: 'success'
					});
				}, function (e) {
					alert('Can not copy')
					console.log(e)
				})
			},
			async confirmReset(row){
				let _this = this
				try{
					let data = await subsysUpdateKey(row.id)
					console.log(data)
					let template = `私钥：<span style="color: #FFBA1A;word-break: break-all;">${data}<span>`
					this.$alert(template,"重置私钥", {
						dangerouslyUseHTMLString: true,
						confirmButtonText: '复制',
						callback: function(action,instance){
							console.log(action,instance)
							_this.copy(data)
						}
					});
				}catch(e){
					console.log(e)
				}
			},
			reset(row){//重置私钥
				console.log(row)
				let _this = this
				this.$confirm('此操作将重置私钥和公钥, 是否继续?', '温馨提示', {
          confirmButtonText: '重置',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
					_this.confirmReset(row)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消重置'
          });
        });


			},

			toPath(type,row) {
				let path = ''
				let query = {}
				if(type == "add"){//添加外蛋系统列表
					path = '/subsys/add'
				}else if(type == "systemUser"){
					/* 2022-05-13
					 * 外蛋内使用人数和item维护暂不开发
					*/
					alert('功能开发中，敬请期待')
					// path = '/subsys/user'
					// query = {
					// 	id: row.id || ''
					// }
				}else if(type == "details"){
					path = '/subsys/details'
					query = {id: row.id}
				}else if(type == "edit"){
					path = '/subsys/add'
					query = {id: row.id}
				}
        this.$router.push({
					path,
					query,
				});//传参
			}
		},
	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>