<template>
	<div>
		<div class="sub-nav">
			<div class="sub-nav-common sub-nav-title">外蛋内使用人员</div>
		</div>
		<div class="search">
			<div class="item clearfix">
				<div class="label clearfix l">
					<span class="label-txt l">手机号码：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" v-model="input2">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">姓名：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" v-model="input2">
						</el-input>
					</div>
				</div>
				<div class="label clearfix l">
					<span class="label-txt l">状态：</span>
					<div class="label-input l">
						<el-input placeholder="请输入内容" v-model="input2">
						</el-input>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<el-table :data="tableData" stripe style="width: 100%">
				<el-table-column prop="date" label="手机号码" width="180">
				</el-table-column>
				<el-table-column prop="name" label="姓名" width="180">
				</el-table-column>
				<el-table-column  prop="address" label="状态">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
	// import { subsys } from '@/api/permission'
	export default {
		data() {
			return {
				tableData: []
			}
		},
		created() {
			// this.getList()
		},
		methods: {
			// async getList(){
			// 	try{
			// 		let data = await subsys()
			// 		this.tableData = data.results
			// 		console.log(data)
			// 	}catch(e){
			// 		console.log(e)
			// 	}
			// },
		},
	}
</script>
<style>
	div {
		font-size: 20px;
	}
</style>